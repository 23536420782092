.market {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

[data-theme="dark"] .market {
  background: #141414;
}

.card-count-market {
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  font-size: 30px;
  font-weight: bold;

  background: #e3ba27;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: top;
}

.action-log {
  position: relative;
  overflow: auto;
  height: 700px;
  width: 600px;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  margin-left: 100px;
}
