.player-actions {
  position: sticky;
  bottom: 5px;
  padding: 5px;
  background: #001529;
  border-radius: 8px;
}

[data-theme="dark"] .player-actions {
  background: #141414;
}

.action-buttons {
  margin-right: 20px;
  margin-bottom: 10px;
}

.player-actions-game-status {
  color: #fff;
  display: inline;
  margin-left: 100px;
  font-size: 30px;
}
