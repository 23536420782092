.layout {
  height: 100%;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.nav-link {
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}

.nav-link-login {
  float: right;
  margin-right: 0;
  height: 31px;
  font-weight: bold;
  font-size: 20px;
}
