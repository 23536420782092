.player-area {
  padding: 10px;
  background: #fff;
}

[data-theme="dark"] .player-area {
  background: #141414;
}

.card-count {
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  font-size: 30px;
  font-weight: bold;

  background: #e3ba27;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: top;
}
